<template>
  <div>
    <validation-observer ref="form">
      <b-form @submit.prevent>
        <fieldset :disabled="disableForm">
          <b-row>
            <!-- Razão Social-->
            <b-col cols="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="nome fantasia"
                rules="required"
              >
                <b-form-group label="Razão Social" label-for="v-nomefantasia">
                  <b-form-input
                    id="v-nomefantasia"
                    v-model="fornecedorInforPersonal.nomeFantasia"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Razão Social"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Nome Fantasia -->
            <b-col cols="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="nome"
                rules="required"
              >
                <b-form-group label="Nome Fantasia" label-for="v-nome">
                  <b-form-input
                    id="v-nome"
                    v-model="fornecedorInforPersonal.nome"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nome Fantasia"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Representante legal-->
            <b-col cols="12" lg="12">
              <b-form-group
                label="Representante Legal"
                label-for="v-representanteLegal"
              >
                <b-form-input
                  id="v-representanteLegal"
                  v-model="fornecedorInforPersonal.representanteLegal"
                  placeholder="Representante Legal"
                />
              </b-form-group>
            </b-col>

            <!-- cnpj -->
            <b-col cols="12" lg="6">
              <validation-provider
                #default="{ errors }"
                name="CNPJ"
                rules="required"
              >
                <b-form-group label="CNPJ" label-for="v-cnpj">
                  <b-form-input
                    id="v-cnpj"
                    v-model="fornecedorInforPersonal.cnpj"
                    placeholder="cnpj"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- cnae -->
            <b-col cols="12" lg="6">
              <b-form-group label="CNAE" label-for="v-cnae">
                <b-form-input
                  id="v-cnae"
                  v-model="fornecedorInforPersonal.cnae"
                  placeholder="cnae"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group label="É MEI?">
                <b-form-checkbox
                  v-model="fornecedorInforPersonal.mei"
                  :checked="fornecedorInforPersonal.mei"
                  name="check-button"
                  switch
                  inline
                >
                  {{ fornecedorInforPersonal.mei ? 'Sim' : 'Não' }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- CPF -->
            <b-col v-if="fornecedorInforPersonal.mei" cols="12" lg="6">
              <validation-provider
                #default="{ errors }"
                name="cpf"
                rules="required|regex:^[\d]{3}.[\d]{3}.[\d]{3}-[\d]{2}$"
              >
                <b-form-group label="CPF" label-for="v-cpf">
                  <cleave
                    id="v-cpf"
                    v-model="fornecedorInforPersonal.cpf"
                    placeholder="cpf"
                    class="form-control"
                    :class="[errors.length > 0 ? 'is-invalid' : '']"
                    :raw="false"
                    :options="options.maskCpf"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col cols="12" lg="12">
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="email"
              >
                <b-form-group label="E-mail" label-for="v-email">
                  <b-form-input
                    id="v-email"
                    v-model="fornecedorInforPersonal.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--naturezaFiscal -->
            <b-col cols="12" lg="6">
              <validation-provider
                #default="{ errors }"
                name="Natureza Fiscal"
                rules="required"
              >
                <b-form-group
                  label="Natureza Fiscal"
                  label-for="v-naturezaFiscal"
                >
                  <v-select
                    v-model="fornecedorInforPersonal.naturezaFiscal"
                    :disabled="disableForm"
                    label="nome"
                    :options="comboNaturezaFiscals"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!--Tipo Registro -->
            <b-col cols="12" lg="6">
              <b-form-group label="Tipo Registro" label-for="v-tipoRegistro">
                <v-select
                  v-model="fornecedorInforPersonal.tipoRegistro"
                  :disabled="disableForm"
                  label="nome"
                  :options="comboTypesRegister"
                />
              </b-form-group>
            </b-col>

            <!-- capitalSocial -->
            <b-col cols="12">
              <b-form-group label="Capital Social" label-for="v-capitalSocial">
                <cleave
                  id="v-capitalSocial"
                  v-model.number="fornecedorInforPersonal.capitalSocial"
                  class="form-control"
                  :raw="true"
                  :options="options.number"
                  placeholder="capitalSocial"
                />
              </b-form-group>
            </b-col>

            <!-- numeroJuntaComercial -->
            <b-col cols="12" lg="6">
              <b-form-group
                label="Número da Junta Comercial"
                label-for="v-numeroJuntaComercial"
              >
                <b-form-input
                  id="v-numeroJuntaComercial"
                  v-model="fornecedorInforPersonal.numeroJuntaComercial"
                  placeholder="capital Social"
                />
              </b-form-group>
            </b-col>

            <!-- inscricaoEstadual -->
            <b-col cols="12" lg="6">
              <b-form-group
                label="Inscrição Estadual"
                label-for="v-inscricaoEstadual"
              >
                <b-form-input
                  id="v-inscricaoEstadual"
                  v-model="fornecedorInforPersonal.inscricaoEstadual"
                  placeholder="Inscrição Estadual"
                />
              </b-form-group>
            </b-col>

            <!-- inscricaoMunicipal -->
            <b-col cols="12" lg="6">
              <b-form-group
                label="Inscrição Municipal"
                label-for="v-inscricaoMunicipal"
              >
                <b-form-input
                  id="v-inscricaoMunicipal"
                  v-model="fornecedorInforPersonal.inscricaoMunicipal"
                  placeholder="Inscrição Municipal"
                />
              </b-form-group>
            </b-col>

            <!-- first name -->
            <b-col cols="12">
              <label for="textarea-default">Observações</label>
              <b-form-textarea
                id="textarea-default"
                v-model="fornecedorInforPersonal.observacao"
                placeholder="Textarea"
                rows="3"
              />
            </b-col>
          </b-row>
        </fieldset>
        <!-- submit and reset -->
        <ButtonsFormVue
          :disable-form="disableForm"
          :loading-button="loading"
          :permission-insert="verificarPermissao('ROLE_ADMIM_INSERIR')"
          :permission-edit="verificarPermissao('ROLE_ADMIM_ATUALIZAR')"
          @save="save"
          @edit="edit"
          @cancel="cancel"
        />
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import mixinsGlobal from '@/mixins'
import ButtonsFormVue from '@/layouts/components/ButtonsForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    vSelect,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    ButtonsFormVue
  },
  directives: {
    Ripple
  },
  mixins: [mixinsGlobal],
  watch: {
    disableForm() {
      this.aplicarEstiloDisabilitadoEmCampoData()
    }
  },
  computed: {
    ...mapState('combosModule', {
      comboNaturezaFiscals: (state) => state.comboNaturezaFiscals
    }),
    ...mapState('fornecedorAdministrativeModule', {
      fullFornecedor: (state) => state.fullFornecedor,
      comboTypesRegister: (state) => state.comboTypesRegister
    })
  },
  data() {
    return {
      loading: false,
      required,
      fornecedorInforPersonal: {
        id: null,
        matricula: '',
        nome: '',
        email: '',
        tipoRegistro: null,
        observacao: null,
        enderecos: [],
        fones: [],
        nomeFantasia: '',
        cnpj: '',
        cpf: '',
        cnae: null,
        inscricaoEstadual: null,
        inscricaoMunicipal: null,
        capitalSocial: null,
        numeroJuntaComercial: null,
        naturezaFiscal: null,
        mei: false,
        representanteLegal: ''
      },
      disableForm: true,
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
        },
        maskCpf: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        }
      }
    }
  },
  mounted() {
    this.loadDadosPerssoais(this.fullFornecedor)
    this.aplicarEstiloDisabilitadoEmCampoData()
  },
  methods: {
    loadDadosPerssoais(data) {
      this.fornecedorInforPersonal = {
        id: data.id,
        matricula: data.matricula,
        nome: data.nome,
        email: data.email,
        tipoRegistro: data.tipoRegistro,
        observacao: data.observacao,
        nomeFantasia: data.nomeFantasia,
        cnpj: data.cnpj,
        cpf: data.cpf,
        cnae: data.cnae,
        inscricaoEstadual: data.inscricaoEstadual,
        inscricaoMunicipal: data.inscricaoMunicipal,
        capitalSocial: data.capitalSocial,
        numeroJuntaComercial: data.numeroJuntaComercial,
        naturezaFiscal:
          data.naturezaFiscal && data.naturezaFiscal.id
            ? data.naturezaFiscal
            : null,
        mei: data.mei,
        representanteLegal: data.representanteLegal
      }
    },
    save() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.loading = true
          const data = JSON.parse(JSON.stringify(this.fornecedorInforPersonal))
          data.tipoRegistro = data.tipoRegistro.id
          data.naturezaFiscal = data.naturezaFiscal.id
          this.$store
            .dispatch('fornecedorAdministrativeModule/editFornecedor', data)
            .then((response) => {
              if (response.response) {
                if (response.response.data.errors.length > 0) {
                  this.MensagemError(
                    `Falha ao Editar - ${response.response.data.errors[0].fieldName}`,
                    response.response.data.errors[0].message
                  )
                  this.loading = false
                  return
                }
                this.MensagemError(
                  'Falha ao Editar',
                  response.response.data.error
                )
                this.loading = false
                return
              }
              if (response.error) {
                this.MensagemError('Falha ao Editar', response.message)
                return
              }
              this.$store
                .dispatch(
                  'fornecedorAdministrativeModule/prepareToEditFull',
                  response.data
                )
                .then(() => {
                  this.showMessageSuccess('Editar', 'Editado com sucesso!')
                  this.$refs.form.reset()
                  this.cancel()
                  this.loadDadosPerssoais(response.data)
                })
            })
        }
      })
    },
    edit() {
      this.disableForm = false
    },
    cancel() {
      this.disableForm = true
      this.loading = false
      this.loadDadosPerssoais(this.fullFornecedor)
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
